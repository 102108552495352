import React, { useState } from 'react';
import { Host } from '../Data';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { loginSuccess } from "../Redux/Admin_slice"
import { useNavigate, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [customErr, setCustomErr] = useState('');
    const dispatch = useDispatch();
    const navigation = useNavigate();


    const CloseErrorMassage = () => {
        setCustomErr('');
    }


    const LoginFunc = async (e) => {

        e.preventDefault();

        if (!email || !password) {

            toast.error('Please fill all the fields');

        } else {

            try {
                const loginData = {
                    email,
                    password
                }

                const res = await axios.post(`${Host}/api/admin/signin`, loginData);
                console.log(res.data);
                dispatch(loginSuccess(res.data));
                navigation('/');

            } catch (error) {

                if (error.response.data === "authentication fail") {

                    toast.error("Authentication fail");
                }
                console.log(error.response.data);
            }
        }
    }

    return (
        <div className=' bg-gray-100 h-screen w-full flex justify-center items-center relative'>
            <div className=' w-full sm:w-[450px]'>
                <div className=' w-full sm:w-[450px] bg-white rounded-lg py-5 shadow shadow-gray-200'>
                    <h2 className=' text-center text-3xl pt-5 font-semibold'>Login</h2>
                    {
                        customErr &&
                        <div className=' w-full px-10 mt-5'>
                            <div className=' w-full bg-red-200 p-3 rounded'>
                                <p className=' text-red-700'>{customErr}</p>
                            </div>
                        </div>
                    }
                    <form className=' w-full py-5 px-5 sm:px-10' onSubmit={LoginFunc}>
                        <div className=' w-full'>
                            <label htmlFor="">Email</label>
                            <input onFocus={CloseErrorMassage} onChange={(e) => { setEmail(e.target.value) }} value={email} type="email" placeholder='Email' required={true} className='inputFild focus:outline-none' />
                        </div>
                        <div className=' w-full'>
                            <label htmlFor="">Password</label>
                            <input onFocus={CloseErrorMassage} onChange={(e) => { setPassword(e.target.value) }} value={password} type="password" placeholder='Password' required={true} className='inputFild focus:outline-none' />
                        </div>
                        <div className=' w-full flex justify-center mt-5'>
                            <button type='submit' className=' w-full text-white font-semibold px-10 py-2 bg-blue-600 hover:bg-blue-700 rounded '>Login</button>
                        </div>
                    </form>
                </div>
                <p className=' mt-5 text-center'>Do not have an account ? <Link to="/register" className=' text-blue-600'>Register</Link></p>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default Login