import React from 'react'
import { useSelector } from 'react-redux';

function Card() {
    const Allusers = useSelector(state => state.Allusers.Allusers);

    return (
        <div className=' w-full bg-white shadow shadow-gray-300 p-5 h-40 rounded'>
            <p className=' text-gray-500'>Total User</p>
            <p className=' text-xl font-semibold my-3'>{Allusers.length}</p>
            <p className=' text-gray-500 font-semibold text-sm'><span className=' bg-blue-100 text-blue-600 text-sm mr-2 rounded'>+$20.9k</span>Since last week</p>
        </div>
    )
}

export default Card