import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";


function PrivateRoute({ children }) {

    const Admin = useSelector(state => state.Admin.Admin);

    return (
        Admin ? children : <Navigate to="/login" />
    )
}

export default PrivateRoute