import { configureStore } from '@reduxjs/toolkit';
import AdminReducer from './Admin_slice';
import AllusersReducer from './Allusers_slice';


export const store = configureStore({
    reducer: {
        Admin: AdminReducer,
        Allusers: AllusersReducer
    },
})