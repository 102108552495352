import { HashRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Register from "./Pages/Register";
import Login from "./Pages/Login";
import SingleUser from "./Pages/SingleUser";
import AddUser from "./Pages/AddUser";
import Profile from "./Pages/Profile";
import PrivateRoute from "./Components/PrivateRoute";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
        <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path="/add_user" element={<PrivateRoute><AddUser /></PrivateRoute>} />
        <Route path="/singleuser/:userId" element={<PrivateRoute><SingleUser /></PrivateRoute>} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </HashRouter>

  );
}

export default App;
