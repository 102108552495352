import React from 'react'
import { AiOutlineHome, AiOutlineUserAdd } from 'react-icons/ai'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { FaUserCog } from 'react-icons/fa'

function Sidebar({ AddUser, Home, Profile }) {

    return (
        <div className={`bg-white pt-24 shadow shadow-gray-300 w-[70px] sm:w-[250px] min-h-screen rounded px-5 sm:px-8`}>
            <ul>
                <Link to="/">
                    <li className=' flex items-center group cursor-pointer mb-5'>
                        <AiOutlineHome className={`${Home ? "text-blue-500" : " text-gray-500"} text-xl mr-3 group-hover:text-blue-500`} />
                        <span className={`${Home ? "text-blue-500" : " text-gray-500"} mr-3 font-semibold group-hover:text-blue-500 hidden sm:inline-block`}>Home</span>
                    </li>
                </Link>
                <Link to="/add_user">
                    <li className=' flex items-center group cursor-pointer my-5'>
                        <AiOutlineUserAdd className={`${AddUser ? "text-blue-500" : " text-gray-500"} text-xl mr-3 group-hover:text-blue-500`} />
                        <span className={`${AddUser ? "text-blue-500" : " text-gray-500"} mr-3 font-semibold group-hover:text-blue-500 hidden sm:inline-block`}>Add User</span>
                    </li>
                </Link>
                <Link to="/profile">
                    <li className=' flex items-center group cursor-pointer my-5'>
                        <FaUserCog className={`${Profile ? "text-blue-500" : " text-gray-500"} text-xl mr-3 group-hover:text-blue-500`} />
                        <span className={`${Profile ? "text-blue-500" : " text-gray-500"} mr-3 font-semibold group-hover:text-blue-500 hidden sm:inline-block`}>Profile</span>
                    </li>
                </Link>
            </ul>

        </div>
    )
}

export default Sidebar