import React, { useEffect } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Host } from '../Data';
import { allusersLoadingSuccess } from "../Redux/Allusers_slice";
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import AllUsers from '../Components/AllUsers';
import Card from '../Components/Card';

function Home() {

    const Admin = useSelector(state => state.Admin.Admin);
    const dispatch = useDispatch();

    useEffect(() => {

        const get_all_users = async () => {
            try {
                const response = await axios.get(`${Host}/api/admin/get_all_users`, {
                    headers: {
                        'Authorization': `Bearer ${Admin.token}`
                    }
                });

                dispatch(allusersLoadingSuccess(response.data.users));

            } catch (err) {
                console.log(err)
            }
        }
        get_all_users();

    }, [Admin.token])


    return (
        <div className=' bg-gray-100 min-h-screen overflow-hidden'>
            <Header />
            <div className='pt-18 flex w-full'>
                <Sidebar Home />
                <div className='pt-20 ml-3 sm:ml-5 w-[calc(100%-70px)] sm:w-[calc(100%-250px)]'>
                    <div className='w-full pt-5 md:pt-10 sm:px-10 md:px-16 lg:px-20 xl:px-32'>
                        <div className='w-full pr-5'>
                            <div className={` w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5 mb-5`}>
                                <Card />
                                <Card />
                                <Card />
                                <Card />
                            </div>
                            <AllUsers />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Home