import React, { useState } from 'react'
import Header from '../Components/Header'
import { Host } from '../Data';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addUserSuccess } from '../Redux/Allusers_slice';
import Sidebar from '../Components/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddUser() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [whatsappNumber, setWhatsappNumber] = useState('');
    const [phone, setPhone] = useState('');
    const Admin = useSelector(state => state.Admin.Admin);
    const dispatch = useDispatch();

    const SubmitData = async (e) => {

        e.preventDefault();

        if (!firstName || !lastName || !email || !password || !confirmPassword || !whatsappNumber || !phone) {

            toast.error('Please fill all the fields');
            return;
        }

        if (password !== confirmPassword) {

            toast.error('password and confirm password not match');
            return;
        }

        const userData = {
            firstName,
            lastName,
            email,
            password,
            whatsappNumber,
            phone
        }

        try {

            const response = await axios.post(`${Host}/api/admin/create_user`, userData, {
                headers: {
                    'Authorization': `Bearer ${Admin.token}`
                }
            });

            setFirstName('');
            setLastName('');
            setEmail('');
            setPassword('');
            setConfirmPassword('');
            setWhatsappNumber('');
            setPhone('');
            dispatch(addUserSuccess(response.data));
            toast.success('User added successfully');

        } catch (err) {

            if (err.response.data === 'admin alrady register in this email') {

                toast.error("Admin already register in this email!")

            } else if (err.response.data === 'Phone number already exist') {

                toast.error("Phone number already exist!")

            } else if (err.response.data === 'user alrady register in this email') {

                toast.error("User already register in this email!")

            }
            console.log(err);
        }
    };


    return (

        <div className=' bg-gray-100 min-h-screen w-full'>
            <Header />
            <div className='pt-18 flex'>
                <Sidebar AddUser />
                <div className=' w-[calc(100%-70px)] sm:w-[calc(100%-250px)] pt-20 ml-3 sm:ml-5'>
                    <div className=' w-full flex justify-center pt-5 md:pt-10 sm:px-16 md:px-20 lg:px-28 xl:px-40'>
                        <div className=' w-full bg-white rounded-lg shadow shadow-gray-200 flex justify-center overflow-hidden'>
                            <div className=' w-full py-5 bg-white rounded-lg shadow shadow-gray-200'>
                                <h2 className=' text-center text-lg sm:text-2xl md:text-3xl pt-5 font-semibold'>Add User</h2>
                                <form className=' w-full grid grid-cols-1 md:grid-cols-2 gap-4 py-5 px-5 sm:px-10' onSubmit={SubmitData}>
                                    <div className=' w-full'>
                                        <label htmlFor="">First Name</label>
                                        <input onChange={(e) => { setFirstName(e.target.value) }} value={firstName} type="text" placeholder='Frist name' required={true} className='inputFild focus:outline-none' />
                                    </div>
                                    <div className=' w-full'>
                                        <label htmlFor="">Last Name</label>
                                        <input onChange={(e) => { setLastName(e.target.value) }} value={lastName} type="text" placeholder='Last name' required={true} className='inputFild focus:outline-none' />
                                    </div>
                                    <div className=' w-full'>
                                        <label htmlFor="">Phone Number</label>
                                        <input onChange={(e) => { setPhone(e.target.value) }} value={phone} type="number" placeholder='Phone number' required={true} className='inputFild focus:outline-none' />
                                    </div>
                                    <div className=' w-full'>
                                        <label htmlFor="">Email</label>
                                        <input onChange={(e) => { setEmail(e.target.value) }} value={email} type="email" placeholder='Email' required={true} className='inputFild focus:outline-none' />
                                    </div>
                                    <div className=' w-full'>
                                        <label htmlFor="">Password</label>
                                        <input onChange={(e) => { setPassword(e.target.value) }} value={password} type="password" placeholder='Password' required={true} className='inputFild focus:outline-none' />
                                    </div>
                                    <div className=' w-full'>
                                        <label htmlFor="">Confirm Password</label>
                                        <input onChange={(e) => { setConfirmPassword(e.target.value) }} value={confirmPassword} type="password" required={true} placeholder='Confirm Password' className='inputFild focus:outline-none' />
                                    </div>
                                    <div className=' w-full'>
                                        <label htmlFor="">Whatsapp Number</label>
                                        <input onChange={(e) => { setWhatsappNumber(e.target.value) }} value={whatsappNumber} type="text" required={true} placeholder='Whatsapp Number' className='inputFild focus:outline-none' />
                                    </div>
                                    <div className=' w-full flex justify-center mt-5'>
                                        <button type='submit' className=' w-full border-[1px] rounded p-1 text-blue-500 shadow shadow-blue-200 hover:shadow-lg hover:text-blue-600 transition-all duration-100 ease-in sm:text-lg'>Create User</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default AddUser