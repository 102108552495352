import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function AllUsers() {

    const Allusers = useSelector(state => state.Allusers.Allusers);

    return (
        <div className=' w-full overflow-x-scroll lg:overflow-hidden '>
            <div className=' w-[800px] lg:w-full bg-white shadow p-5 sm:p-10 rounded'>
                <p className=' pb-4 border-b-[1px] border-gray-100 font-semibold'>All Users</p>
                <div className=' w-full'>
                    <div className=' w-full grid grid-cols-6 py-4 border-b-[1px] border-gray-100 font-semibold'>
                        <span>First Name</span>
                        <span>Last Name</span>
                        <span className=' col-span-2'>Email</span>
                        <span>Phone Number</span>
                        {/* <span>User Role</span> */}
                    </div>
                </div>
                {
                    Allusers.length > 0 && Allusers.map((user, index) => {
                        return (
                            <div key={index} className=' w-full grid grid-cols-6 py-4 border-b-[1px] border-gray-100'>
                                <div className=' w-full flex items-center'>
                                    <span className=' capitalize p-1 rounded bg-green-100 text-green-700'>{user.firstName}</span>
                                </div>
                                <div className=' w-full flex items-center'>
                                    <span className=' capitalize p-1 rounded bg-red-100 text-red-700'>{user.lastName}</span>
                                </div>

                                <div className=' col-span-2 flex items-center'>
                                    <span className=' bg-blue-100 p-1 rounded text-blue-700'>{user.email}</span>
                                </div>
                                <div className=' w-full flex items-center'>
                                    <span className=' capitalize bg-purple-100 rounded p-1 text-purple-700'>{user.phone}</span>
                                </div>
                                {/* <span className=' capitalize'>{user.role}</span> */}
                                <Link to={`/singleuser/${user._id}`}>
                                    <button className=' border-[1px] rounded p-1 text-blue-500 shadow shadow-blue-200 hover:scale-110 hover:text-blue-600 transition-all duration-100 ease-in'>View Details</button>
                                </Link>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default AllUsers