import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Host } from '../Data';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Components/Header';
import { deleteUserSuccess, updateUserSuccess } from "../Redux/Allusers_slice";
import Sidebar from '../Components/Sidebar';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function SingleUser() {

    const [isUpdateModeOpen, setIsUpdateModeOpen] = useState(false);
    const [user, setUser] = useState(null);
    const location = useLocation();
    const userId = location.pathname.split('/')[2];
    const Admin = useSelector(state => state.Admin.Admin);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [whatsappNumber, setWhatsappNumber] = useState('');
    const [phone, setPhone] = useState('');
    const [customErr, setCustomErr] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const dispatch = useDispatch()
    const Allusers = useSelector(state => state.Allusers.Allusers);
    const navigation = useNavigate();

    const ToggleUpdateMode = () => {
        setIsUpdateModeOpen(!isUpdateModeOpen);
    }

    const CloseErrorMessage = () => {
        setCustomErr('');
        setSuccessMessage('');
    }
    const closeUpdateMode = () => {

        setIsUpdateModeOpen(false)
        CloseErrorMessage();
        setConfirmPassword('');
        setPassword('');
    }

    const UpdateUserData = async () => {

        if (password && password !== confirmPassword) {
            setCustomErr('password and confirm password not matched');
            return;
        }

        const userData = {
            firstName,
            lastName,
            email,
            whatsappNumber,
            phone
        }

        if (password) {
            userData.password = password;
        }

        try {

            const response = await axios.put(`${Host}/api/admin/update_user/${userId}`, userData, {
                headers: {
                    "Authorization": `Bearer ${Admin.token}`
                }
            });

            dispatch(updateUserSuccess(response.data))
            setConfirmPassword('');
            setPassword('');
            toast.success('User data updated successfully');

        } catch (err) {

            if (err.response.data === 'admin alrady register in this email') {

                toast.error("Admin already register in this email!")

            } else if (err.response.data === 'Phone number already exist') {

                toast.error("Phone number already exist!")

            } else if (err.response.data === 'user alrady register in this email') {

                toast.error("User already register in this email!")

            }
            console.log(err)
        }
    }

    const DeleteUser = async () => {

        try {

            await axios.delete(`${Host}/api/admin/delete_user/${userId}`, {
                headers: {
                    "Authorization": `Bearer ${Admin.token}`
                }
            });

            dispatch(deleteUserSuccess(userId));
            navigation('/');

        } catch (error) {

            console.log(error)
        }
    }

    useEffect(() => {

        const getSingleUser = async () => {
            try {
                const response = await axios.get(`${Host}/api/admin/get_user/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${Admin.token}`
                    }
                });

                setUser(response.data.user);
                setFirstName(response.data.user.firstName);
                setLastName(response.data.user.lastName);
                setEmail(response.data.user.email);
                setPhone(response.data.user.phone);
                setWhatsappNumber(response.data.user.whatsappNumber);

            } catch (err) {
                console.log(err)
            }
        }

        getSingleUser();

    }, [userId, Allusers])



    return (



        <div className=' bg-gray-100 min-h-screen overflow-y-scroll w-full'>
            <Header />
            <div className='pt-18 flex'>
                <Sidebar />

                <div className=' w-full pt-24 px-5 sm:px-16 md:px-20 lg:px-28 xl:px-40'>
                    <div className=' w-full  bg-white rounded-lg shadow shadow-gray-200 flex overflow-hidden'>
                        <div className=' w-full p-10'>
                            <p className=' font-semibold text-2xl text-center'>User Details</p>

                            {/* buttons */}

                            <div className=' flex justify-center sm:justify-end w-full my-5'>
                                {
                                    isUpdateModeOpen ?
                                        <button onClick={closeUpdateMode} className=' border-[1] rounded p-1 text-red-500 shadow shadow-red-300 ml-2 hover:scale-110 hover:text-red-600 transition-all duration-100 ease-in mr-3' >Close</button>
                                        :
                                        <button onClick={DeleteUser} className=' border-[1] rounded p-1 text-red-500 shadow shadow-red-300 ml-2 hover:scale-110 hover:text-red-600 transition-all duration-100 ease-in mr-3' >Delete</button>
                                }
                                {
                                    isUpdateModeOpen ?
                                        <button onClick={UpdateUserData} className=' border-[1px] rounded p-1 text-blue-500 shadow shadow-blue-200 hover:scale-110 hover:text-blue-600 transition-all duration-100 ease-in' >Save</button>
                                        :
                                        <button onClick={ToggleUpdateMode} className=' border-[1px] rounded p-1 text-blue-500 shadow shadow-blue-200 hover:scale-110 hover:text-blue-600 transition-all duration-100 ease-in' >Update</button>
                                }
                            </div>

                            {/* input filds */}

                            {
                                user &&
                                <div className=' w-full'>
                                    <div className=' w-full'>
                                        {
                                            !isUpdateModeOpen &&
                                            <p className=" text-base sm:text-lg font-semibold mb-2">User Name</p>
                                        }
                                        {
                                            isUpdateModeOpen ?
                                                <div className=' w-full grid grid-cols-1 sm:grid-cols-2 gap-3'>
                                                    <div className=' w-full'>
                                                        <label className=' font-semibold' htmlFor="">First Name :</label>
                                                        <input onFocus={CloseErrorMessage} onChange={(e) => { setFirstName(e.target.value) }} value={firstName} type="text" placeholder='Frist name' required={true} className='inputFild focus:outline-none' />
                                                    </div>
                                                    <div className=' w-full'>
                                                        <label className=' font-semibold' htmlFor="">Last Name :</label>
                                                        <input onFocus={CloseErrorMessage} onChange={(e) => { setLastName(e.target.value) }} value={lastName} type="text" placeholder='Last name' required={true} className='inputFild focus:outline-none' />
                                                    </div>
                                                </div>
                                                :
                                                <p className=' mt-1 text-gray-700'>{user && user.firstName + " " + user.lastName}</p>
                                        }
                                    </div>

                                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-3'>

                                        <div className=''>
                                            {
                                                isUpdateModeOpen ?
                                                    <div className=' w-full'>
                                                        <label className=' font-semibold' htmlFor="">Email :</label>
                                                        <input onFocus={CloseErrorMessage} onChange={(e) => { setEmail(e.target.value) }} value={email} type="email" placeholder='Email' required={true} className='inputFild focus:outline-none' />
                                                    </div>
                                                    :
                                                    <div>
                                                        <p className=" text-base sm:text-lg font-semibold mb-2">User Email</p>
                                                        <p className=' mt-1 text-gray-700'>{user && user.email}</p>
                                                    </div>

                                            }
                                        </div>
                                        <div className=''>
                                            {
                                                isUpdateModeOpen ?
                                                    <div className=' w-full'>
                                                        <label className=' font-semibold' htmlFor="">Phone Mobile Number :</label>
                                                        <input onFocus={CloseErrorMessage} onChange={(e) => { setPhone(e.target.value) }} value={phone} type="number" placeholder='Phone number' required={true} className='inputFild focus:outline-none' />
                                                    </div>
                                                    :
                                                    <div>
                                                        <p className=' text-base sm:text-lg font-semibold mb-2'>User Mobile Number</p>
                                                        <p className=' mt-1 text-gray-700'>{user && user.phone}</p>
                                                    </div>

                                            }
                                        </div>
                                        <div className=''>
                                            {
                                                isUpdateModeOpen ?
                                                    <div className=' w-full'>
                                                        <label className=' font-semibold' htmlFor="">Whatsapp Number :</label>
                                                        <input onFocus={CloseErrorMessage} onChange={(e) => { setWhatsappNumber(e.target.value) }} value={whatsappNumber} type="text" required={true} placeholder='Whatsapp Number' className='inputFild focus:outline-none' />
                                                    </div>
                                                    :
                                                    <div>
                                                        <p className=' text-base sm:text-lg font-semibold mb-2'>User Whatsapp Number</p>
                                                        <p className=' mt-1 text-gray-700'>{user && user.whatsappNumber}</p>
                                                    </div>
                                            }
                                        </div>
                                        {
                                            isUpdateModeOpen &&

                                            <div className=' w-full'>
                                                <label className=' font-semibold' htmlFor="">User Password :</label>
                                                <input onFocus={CloseErrorMessage} onChange={(e) => { setPassword(e.target.value) }} value={password} type="text" placeholder='Pasword' className='inputFild focus:outline-none' />
                                            </div>

                                        }

                                        {
                                            isUpdateModeOpen &&

                                            <div className=' w-full'>
                                                <label className=' font-semibold' htmlFor="">Confirm Password :</label>
                                                <input onFocus={CloseErrorMessage} onChange={(e) => { setConfirmPassword(e.target.value) }} value={confirmPassword} type="text" placeholder='Confirm Pasword' className='inputFild focus:outline-none' />
                                            </div>
                                        }
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div >
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default SingleUser