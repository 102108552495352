import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Header from '../Components/Header'
import AdminPhoto from "../Photos/user.jpg"
import axios from 'axios'
import { Host } from "../Data"
import { loginSuccess } from '../Redux/Admin_slice'
import Sidebar from '../Components/Sidebar'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Profile() {

    const Admin = useSelector(state => state.Admin.Admin);
    const [isUpdateModeOpen, setIsUpdateModeOpen] = React.useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [customErr, setCustomErr] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigation = useNavigate();
    const dispach = useDispatch();


    const toggleUpdateMode = () => {
        setIsUpdateModeOpen(!isUpdateModeOpen);
    }

    const closeUpdateMode = () => {
        setIsUpdateModeOpen(false);
        setSuccessMessage('');
        setConfirmPassword('');
        setPassword('');
    }

    const UpdateAdminData = async () => {

        if (password && password !== confirmPassword) {

            toast.error('Password does not match');
            return;
        }

        const admin = {
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            email: email,
        }

        if (password) {
            admin.password = password;
        }

        try {

            const response = await axios.put(`${Host}/api/admin/update/${Admin._id}`, admin, {
                headers: {
                    'Authorization': `Bearer ${Admin.token}`
                }
            });

            dispach(loginSuccess(response.data));
            toast.success('Profile updated successfully');
            setConfirmPassword('');
            setPassword('');
            console.log(response.data)

        } catch (error) {

            toast.error("Something went wrong");
        }

    }

    useEffect(() => {

        if (Admin) {
            setFirstName(Admin.firstName);
            setLastName(Admin.lastName);
            setPhone(Admin.phone);
            setEmail(Admin.email);
        }

    }, [Admin]);



    return (


        <div className=' bg-gray-100 min-h-screen overflow-hidden'>
            <Header />
            <div className='pt-18 flex w-full'>
                <Sidebar Profile />
                <div className='pt-20 ml-3 sm:ml-5 w-[calc(100%-70px)] sm:w-[calc(100%-250px)]'>
                    <div className='w-full pt-5 md:pt-10 sm:px-10 md:px-16 lg:px-20 xl:px-32'>
                        <div className='w-full pr-5'>
                            <div className=' w-full bg-white rounded p-5 sm:p-10 relative'>
                                <p className=' text-lg sm:text-xl md:text-center font-semibold mb-5'>Admin Details</p>

                                {
                                    isUpdateModeOpen ?
                                        <button onClick={closeUpdateMode} className='absolute top-5 sm:top-8 sm:right-8 right-5 border-[1] rounded p-1 text-red-500 shadow shadow-red-300 ml-2 hover:scale-110 hover:text-red-600 transition-all duration-100 ease-in mr-3'>Close</button>
                                        :

                                        <button onClick={toggleUpdateMode} className=' border-[1px] rounded p-1 text-blue-500 shadow shadow-blue-200 hover:scale-105 hover:text-blue-600 transition-all duration-100 ease-in absolute top-5 sm:top-8 sm:right-8 right-5 py-[6px] px-2'>Update</button>
                                }
                                <div className=' flex'>
                                    <div className=' w-full'>
                                        {
                                            isUpdateModeOpen ?
                                                <div className=' w-full'>
                                                    <div className=' w-full'>
                                                        <label htmlFor="">First Name</label>
                                                        <input id='' onChange={(e) => { setFirstName(e.target.value) }} value={firstName} type="text" placeholder='Frist name' required={true} className='inputFild focus:outline-none' />
                                                    </div>
                                                    <div className=' w-full'>
                                                        <label htmlFor="">Last Name</label>
                                                        <input onChange={(e) => { setLastName(e.target.value) }} value={lastName} type="text" placeholder='Last name' required={true} className='inputFild focus:outline-none' />
                                                    </div>
                                                    <div className=' w-full'>
                                                        <label htmlFor="">Phone Number</label>
                                                        <input onChange={(e) => { setPhone(e.target.value) }} value={phone} type="number" placeholder='Phone number' required={true} className='inputFild focus:outline-none' />
                                                    </div>
                                                    <div className=' w-full'>
                                                        <label htmlFor="">Email</label>
                                                        <input onChange={(e) => { setEmail(e.target.value) }} value={email} type="email" placeholder='Email' required={true} className='inputFild focus:outline-none' />
                                                    </div>
                                                    <div className=' w-full'>
                                                        <label htmlFor="">Password</label>
                                                        <input onChange={(e) => { setPassword(e.target.value) }} value={password} type="password" placeholder='Password' required={true} className='inputFild focus:outline-none' />
                                                    </div>
                                                    <div className=' w-full'>
                                                        <label htmlFor="">Confirm Password</label>
                                                        <input onChange={(e) => { setConfirmPassword(e.target.value) }} value={confirmPassword} type="password" required={true} placeholder='Confirm Password' className='inputFild focus:outline-none' />
                                                    </div>
                                                    <div className=' w-full flex justify-center mt-5'>
                                                        <button onClick={UpdateAdminData} className='w-full border-[1px] rounded p-2 text-blue-500 shadow shadow-blue-200 hover:shadow-lg hover:text-blue-600 transition-all duration-100 ease-in'>Update</button>
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    <p className=' capitalize font-semibold  sm:text-base md:text-lg my-2'>{Admin.firstName + " " + Admin.lastName}</p>
                                                    <p className='my-1 text-sm sm:text-base text-gray-700'>{Admin.phone}</p>
                                                    <p className='my-1 text-sm sm:text-base text-gray-700'>{Admin.email}</p>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default Profile