import React, { useState } from 'react'
import axios from 'axios'
import { Host } from '../Data';
import { useNavigate, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Register() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigation = useNavigate();

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!firstName || !lastName || !phone || !email || !password || !confirmPassword) {

            toast.error('Please fill all the fields');
            return;

        } else {

            if (password !== confirmPassword) {

                toast.error('Password and confirm password does not match');

            } else {

                const adminData = {
                    firstName,
                    lastName,
                    phone,
                    email,
                    password,
                }

                try {
                    await axios.post(`${Host}/api/admin/signup`, adminData);
                    setFirstName("");
                    setLastName("");
                    setPhone("");
                    setEmail("");
                    setPassword("");
                    setConfirmPassword("");
                    navigation("/login");

                } catch (error) {

                    if (error.response.data === "admin alrady register in this email") {

                        toast.error("Admin already register in this email");

                    } else if (error.response.data === "Phone number already exist") {

                        toast.error("Phone number already exist");

                    } else if (error.response.data === "user alrady register in this email") {

                        toast.error("User already register in this email");
                    }

                    console.log(error);
                }
            }
        }
    }


    return (
        <div className=' bg-gray-100 min-h-screen py-10 w-full flex justify-center items-center'>
            <div className=' w-full sm:w-[450px]'>
                <div className=' w-full sm:w-[450px] py-5 bg-white rounded-lg shadow shadow-gray-200'>
                    <h2 className=' text-center text-3xl pt-5 font-semibold'>Register</h2>
                    <form className=' w-full py-5 px-5 sm:px-10' onSubmit={handleSubmit}>
                        <div className=' w-full'>
                            <label htmlFor="">First Name</label>
                            <input id='' onChange={(e) => { setFirstName(e.target.value) }} value={firstName} type="text" placeholder='Frist name' required={true} className='inputFild focus:outline-none' />
                        </div>
                        <div className=' w-full'>
                            <label htmlFor="">Last Name</label>
                            <input onChange={(e) => { setLastName(e.target.value) }} value={lastName} type="text" placeholder='Last name' required={true} className='inputFild focus:outline-none' />
                        </div>
                        <div className=' w-full'>
                            <label htmlFor="">Phone Number</label>
                            <input onChange={(e) => { setPhone(e.target.value) }} value={phone} type="number" placeholder='Phone number' required={true} className='inputFild focus:outline-none' />
                        </div>
                        <div className=' w-full'>
                            <label htmlFor="">Email</label>
                            <input onChange={(e) => { setEmail(e.target.value) }} value={email} type="email" placeholder='Email' required={true} className='inputFild focus:outline-none' />
                        </div>
                        <div className=' w-full'>
                            <label htmlFor="">Password</label>
                            <input onChange={(e) => { setPassword(e.target.value) }} value={password} type="password" placeholder='Password' required={true} className='inputFild focus:outline-none' />
                        </div>
                        <div className=' w-full'>
                            <label htmlFor="">Confirm Password</label>
                            <input onChange={(e) => { setConfirmPassword(e.target.value) }} value={confirmPassword} type="password" required={true} placeholder='Confirm Password' className='inputFild focus:outline-none' />
                        </div>
                        <div className=' w-full flex justify-center mt-5'>
                            <button type='submit' className=' w-full text-white font-semibold px-10 py-2 bg-blue-600 hover:bg-blue-700 rounded '>Register</button>
                        </div>
                    </form>
                </div>
                <p className=' mt-5 text-center'>Already have an account ? <Link to="/login" className=' text-blue-600'>Login</Link></p>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default Register